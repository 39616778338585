import { postLibrary, postLibraryPublish } from "@/api/library";
import { useVuelidate } from "@vuelidate/core";
import { minLength, maxLength, required, requiredIf, helpers } from "@vuelidate/validators";
export const useLibraryFormStore = defineStore("libraryForm", () => {
  const hasNoSpaces = value => !helpers.req(value) || !(value.trim().indexOf(" ") >= 0);
  const form = reactive({
    libraryName: "",
    accountSelect: null,
    gscPropertySelect: null,
    mergeAccounts: false,
    propertyA: {
      webPropSelect: null,
      profileSelect: null
    },
    propertyB: {
      webPropSelect: null,
      profileSelect: null
    },
    pagePathFilter: ""
  });
  const webPropAisUA = computed(() => form.propertyA.webPropSelect?.api_type == "UA");
  const webPropBisUA = computed(() => form.propertyB.webPropSelect?.api_type == "UA");
  const rules = computed(() => ({
    libraryName: {
      required: helpers.withMessage("A library name is required.", required),
      minLength: helpers.withMessage("Library name should have at least 3 characters.", minLength(3)),
      maxLength: helpers.withMessage("Library name should be less than 100 characters.", maxLength(100))
    },
    pagePathFilter: {
      minLength: helpers.withMessage("Page path filter should have at least 3 characters.", minLength(3)),
      maxLength: helpers.withMessage("Page path filter should be less than 100 characters.", maxLength(100)),
      hasNoSpaces: helpers.withMessage("Page path filter cannot contain spaces.", hasNoSpaces)
    },
    accountSelect: {
      required: helpers.withMessage("A Google Analytics account is required to create a library.", required)
    },
    propertyA: {
      webPropSelect: {
        required: helpers.withMessage("A web property is required to create a library.", required)
      },
      profileSelect: {
        required: helpers.withMessage("A view is required for UA properties.", requiredIf(webPropAisUA))
      }
    },
    propertyB: {
      webPropSelect: {
        required: helpers.withMessage("A UA property is required to merge.", requiredIf(computed(() => form.mergeAccounts)))
      },
      profileSelect: {
        required: helpers.withMessage("A view is required for UA properties.", requiredIf(webPropBisUA))
      }
    }
  }));
  const v$ = useVuelidate(rules, form, {
    $lazy: true
  });
  const ga4Props = computed(() => {
    return form.accountSelect?.properties?.filter(prop => prop.api_type == "GA4");
  });
  const uaProps = computed(() => {
    return form.accountSelect?.properties?.filter(prop => prop.api_type == "UA");
  });
  const propertyAProps = computed(() => {
    if (!form.accountSelect) return;
    if (form.mergeAccounts) {
      return form.accountSelect?.properties?.filter(prop => prop.api_type == "GA4");
    } else {
      return form.accountSelect.properties;
    }
  });
  const propertyBProps = computed(() => {
    return form.accountSelect?.properties?.filter(prop => prop.api_type == "UA");
  });
  watch([() => form.mergeAccounts, () => form.accountSelect], () => {
    propertyReset();
  });
  watch(() => form.propertyA.webPropSelect, newVal => {
    form.propertyA.profileSelect = newVal?.profiles?.length == 1 ? newVal?.profiles[0] : null;
  });
  watch(() => form.propertyB.webPropSelect, newVal => {
    form.propertyB.profileSelect = newVal?.profiles?.length == 1 ? newVal?.profiles[0] : null;
  });
  function propertyReset() {
    form.propertyA.profileSelect = null;
    form.propertyA.webPropSelect = null;
    form.propertyB.profileSelect = null;
    form.propertyB.webPropSelect = null;
  }
  async function handleSubmit(workspaceId) {
    const body = {
      name: form.libraryName,
      filter: form.pagePathFilter,
      account_id: form.accountSelect.id
    };
    if (form.gscPropertySelect) {
      body.gsc_siteurl = form.gscPropertySelect.site_url;
    }
    if (form.mergeAccounts) {
      body.ua_property_id = form.propertyB.profileSelect.id;
      body.ga4_property_id = form.propertyA.webPropSelect.id;
    } else if (form.propertyA.webPropSelect?.api_type == "UA" && form.propertyA.profileSelect) {
      body.ua_property_id = form.propertyA.profileSelect.id;
    } else if (form.propertyA.webPropSelect?.api_type == "GA4") {
      body.ga4_property_id = form.propertyA.webPropSelect.id;
    }
    const res = await postLibrary(workspaceId, body);
    if (res) {
      await postLibraryPublish(workspaceId, res.id);
      return res;
    } else {
      return;
    }
  }
  return {
    ...toRefs(form),
    v$,
    handleSubmit,
    propertyAProps,
    propertyBProps,
    ga4Props,
    uaProps
  };
});