export function round(num, digits = 2) {
	const multiplier = 10 ** digits
	return Math.round(num * multiplier) / multiplier
}

// type = "default" – just add commas i.e. 32,447
// type = "s" – abbreviate without any decimals i.e. 32K
// type = "m" – abbreviate with 1 decimals i.e. 32.4K
// type = "l" – abbreviate with 2 decimals i.e. 32.44K
// type = "%s" – abbreviate percent without any decimals i.e. 32%
// type = "%m" – abbreviate percent with 1 decimals i.e. 32.4%
// type = "%l" – abbreviate percent with 2 decimals i.e. 32.44%
export function numDisplay(num, type = "default") {
	const options = { maximumSignificantDigits: 3, roundingPriority: "lessPrecision" }

	if (typeof num == "number") {
		if (type === "s" || type === "%s") {
			options.maximumFractionDigits = 0
		} else if (type === "m" || type === "%m") {
			options.maximumFractionDigits = 1
		} else if (type === "l" || type === "%l") {
			options.maximumFractionDigits = 2
		}

		if (type === "s" || type === "m" || type === "l") {
			options.notation = "compact"
			options.compactDisplay = "short"
		} else if (type === "%s" || type === "%m" || type === "%l") {
			options.style = "percent"
		}

		return new Intl.NumberFormat("en-US", options).format(num)
	} else if (type == "%s" || type == "%m" || type == "%l") {
		return num + "%"
	} else {
		return num
	}
}