import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";

import { useUserStore } from '@/stores';
import { onMounted } from 'vue';
const _hoisted_1 = {
  class: "w-full h-full min-h-[600px] px-7 fixed inset-0 bg-white z-50 overflow-scroll py-8"
};
const _hoisted_2 = {
  class: "py-6 px-10 w-full h-full max-w-[700px] mx-auto flex flex-col"
};
const _hoisted_3 = {
  key: 0,
  class: "flex justify-center mx-auto my-8 md:mx-12 md:fixed md:top-0 md:right-0"
};
export default {
  __name: 'OnboardingLayout',
  setup(__props) {
    const userStore = useUserStore();
    onMounted(async () => {
      userStore.getWorkspaceLibraries();
    });
    return (_ctx, _cache) => {
      const _component_ButtonDefault = _resolveComponent("ButtonDefault");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]), _unref(userStore).fallbackLibrary ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_ButtonDefault, {
        to: {
          name: 'LibraryOverview',
          params: {
            libraryId: _unref(userStore)?.fallbackLibrary?.id,
            workspaceId: _unref(userStore)?.fallbackLibrary?.workspace
          }
        },
        size: "md"
      }, {
        default: _withCtx(() => [_createTextVNode(" Cancel ")]),
        _: 1
      }, 8, ["to"])])) : _createCommentVNode("", true)]);
    };
  }
};